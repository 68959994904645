<template>
  <layout width="540">
    <template slot="header">
      {{ popupTitle }}
    </template>

    <template>
      <v-form>
        <form-subheader title="General" />
        <v-radio-group v-model="general[0].value" class="ma-0">
          <form-radio
            icon="mdi-volume-high"
            title="All notifications"
            value="all"
          />
          <form-radio
            icon="mdi-star"
            title="Favorites only"
            value="favourites"
          />
          <form-radio icon="mdi-volume-off" value="mute" title="Mute all" />
        </v-radio-group>
        <form-subheader title="Only deliver notifications which" />
        <select-chips
          v-for="item in tags"
          :key="item.id"
          v-model="item.value"
          multiple
          :label="item.title"
          chips
          class="ma-0"
          @drop-item="handleDropChip($event, item.value)"
        />
        <template v-if="isShowSoundInApp">
          <form-subheader title="Options" />
          <form-switcher
            v-for="option in options"
            :key="option.title"
            v-model="option.value"
            :title="option.title"
          />
        </template>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="close">
        Cancel
      </v-btn>
      <v-btn text color="primary" depressed :loading="isLoading" @click="save">
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { ref, reactive, toRefs, computed } from '@vue/composition-api';
import { useMutation } from '@vue/apollo-composable';

import schema from '@/modules/account/schema.graphql';

import Layout from '@/components/popup/PopupLayoutDefault';
import { DELIVERY_CHANNELS } from '@/utils/notificationItems';
import { watch } from 'vue-demi';
import SelectChips from '@/components/_base/SelectChips.vue';

export default {
  name: 'NotificationSettings',
  components: {
    SelectChips,
    Layout
  },
  props: {
    profile: {
      type: Object,
      required: true
    },
    popupTitle: {
      type: String,
      default: 'Notifications settings'
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const state = reactive({
      options: [
        {
          id: 'settingsSoundInTheApp',
          key: 'settingsSoundInTheApp',
          title: 'Sound in the app',
          value: false
        }
      ],
      tags: [
        {
          id: 'settingsIncludeTags',
          title: 'Include tags',
          key: 'settingsIncludeTags',
          value: []
        },
        {
          id: 'settingsExcludeTags',
          title: 'Exclude tags',
          key: 'settingsExcludeTags',
          value: []
        }
      ],
      general: [
        {
          id: 'settingsPreference',
          key: 'settingsPreference',
          value: 'all'
        }
      ]
    });

    const { mutate: changeProperties } = useMutation(
      schema.updateObjectProperties
    );

    const notificationSettings = computed(
      () => props.profile?.objectProperties || []
    );
    const isShowSoundInApp = computed(
      () => props.profile?.deliveryChannel === DELIVERY_CHANNELS.IN_APP
    );

    const close = () => {
      emit('close');
    };

    const save = () => {
      isLoading.value = true;
      let data = [...state.tags, ...state.options, ...state.general].map(
        item => ({
          value: item.value,
          propertyId: item.id
        })
      );

      changeProperties({
        input: {
          propertiesArray: data
        }
      })
        .then(() => {
          close();
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    watch(
      () => props.profile,
      () => {
        const setState = arr => {
          arr.forEach(item => {
            let fromObject = notificationSettings.value.find(
              itemFromObject => itemFromObject.key === item.key
            );

            if (fromObject && fromObject.key === item.key) {
              item.id = fromObject.id;
              item.value = fromObject.value;
            }

            if (!fromObject) {
              item.id = false;
            }
          });
        };

        setState(state.tags);
        setState(state.options);
        setState(state.general);
      },
      {
        immediate: true
      }
    );

    const handleDropChip = (value, data) => {
      data.splice(
        data.findIndex(i => i === value),
        1
      );
    };

    return {
      ...toRefs(state),
      handleDropChip,
      close,
      save,
      isLoading,
      isShowSoundInApp
    };
  }
};
</script>

<style lang="scss">
.theme--light.custom-label .v-label {
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.custom-label .v-label {
  color: #ffffff;
}

.cursor {
  cursor: pointer;
}
</style>
